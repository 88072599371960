// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { favicon, pattern } from "../assets";
import Grow from '@mui/material/Grow';

function Hero() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      
      sx={{
        background: `linear-gradient(120deg, rgba(29,26,41,.7) 32%, rgba(54,55,79,.4) 100%), url(${pattern})`, backgroundRepeat: "no-repeat", backgroundSize: "130%", backgroundPosition: "center",
        height: '85vh',
      }}
    >

      <Box component="div"
        // sx={{
        //   height: "100%",
        //   backgroundImage: {xs: 'none', md: `url(${favicon})`},
        //   backgroundSize: { xs: '200px', md: '250px' },
        //   backgroundRepeat: "no-repeat",
        //   backgroundPosition: "86% center",
        // }}
      >
        <Header />
        <Container maxWidth="md" style={{display: "flex", justifyContent: "center", alignContent: "center", height: "100%"}}>
        <Grow in={true} timeout={1200}>

          <Box p={4} >
            <Typography variant="h1" className="gradient-text" sx={{marginBottom: "2rem", fontWeight: "900", fontSize:{xs: '2rem', md: "4rem"}}}>
            Welcome to clarivoxstop 
            </Typography>
            <Typography variant="h4" gutterBottom className="gradient-text" sx={{fontWeight: "900", fontSize:{xs: '1rem', md: "1.5rem"}, fontStyle: "italic"}} >
            Enjoy a wide variety of games without any charges or the involvement of real money.            </Typography>

          </Box>
          </Grow>

        </Container>
      </Box>
    </Box>
  );
}

export default Hero;
