// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";
import Benefits from "./Benefits";

function CTA() {
  return (
    <Box id="about" sx={{ backgroundColor: "primary.dark" }}>
      <Container maxWidth="lg">
        {/* Image Block */}
        {/* <img
          src={ctaBg}
          alt="CTA"
          style={{ maxWidth: '400px', width: "100%", height: 'auto' }}
        /> */}
        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
            <Typography variant="h2" textAlign="center" gutterBottom>
              About Us
            </Typography>
            <Typography variant="h6" paragraph>
              Welcome to clarivoxstop, a project that started as a fun side venture and grew into something much more. Our platform is a place where you can enjoy an array of online games without any cost and free from the involvement of real money. We believe in learning by doing, and this project has been both educational for us and entertaining for you.
            </Typography>
            <Typography variant="h6" paragraph>
              Our story began with a small group of friends who shared a love for gaming and a desire to create a space where everyone could enjoy games freely. What started as a hobby quickly transformed into a vibrant community of gamers and developers. We are committed to providing a seamless and enjoyable gaming experience for all.
            </Typography>
            <Typography variant="h6" paragraph>
              At clarivoxstop, we prioritize fair play and transparency. Every game on our platform uses certified random number generators to ensure fairness, reflecting our dedication to integrity and trust. We are constantly striving to improve and expand our offerings, always with your enjoyment in mind.
            </Typography>
            <Typography variant="h6" paragraph>
              We're excited about the future and welcome any questions, feedback, or suggestions. If you're a game developer looking to share your creations or just want to say hi, feel free to reach out to us at info@clarivoxstop.com. Join us as we continue to grow and make clarivoxstop the ultimate online playground.
            </Typography>

            {/* <Benefits /> */}
          </Box>
        </Grow>
      </Container>
    </Box>
  );
}

export default CTA;
