import React from 'react';
import { Link } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import {games} from '../data/games.js';

function GameList() {
  return (
    <Container maxWidth="lg">
    <Typography variant="h3" fontWeight="900" marginBottom="2rem" textAlign="center">
    Our games
            </Typography>
      <Grid container spacing={4}>
        {games.map((game) => (
          <Grid item sm={6} md={4} key={game.id} alignItems="center">
            <Card className="game-card">
              <CardActionArea component={Link} to={`/game/${game.id}`}>
                <CardMedia
                  component="img"
                  alt={game.title}
                  height="180"
                  image={game.image}
                  title={game.title}
                  style={{ fontSize: '14px' }}
                />
                <CardHeader
                  title={game.title}
                  className="card-header"
                />
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default GameList;
