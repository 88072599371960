import React from "react";
import {Container,  Box, Typography, Paper} from '@mui/material';
import Header from "./Header";
import Contacts from "./Contacts";
import TopGames from "./TopGames";

export default function About() {
  return (
    <>
      <Header />
      <Container>
        <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
            <Paper elevation={3} sx={{ padding: 5, backgroundColor: "primary.main500" }}>
            <Typography variant="h3" textAlign="center" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body1" paragraph>
              Welcome to clarivoxstop! What started as a quirky side project among friends has blossomed into a beloved platform where gaming and learning unite. We wanted to create a place where games are accessible to everyone, without any charges or the involvement of real money.
            </Typography>
            <Typography variant="body1" paragraph>
              Our journey began with a simple idea: to build a free and enjoyable gaming space. As we delved into this project, we discovered a deeper passion for creating fun and educational experiences. clarivoxstop is the result of countless hours of collaboration, creativity, and a shared love for gaming.
            </Typography>
            <Typography variant="body1" paragraph>
              Here, fair play is at the core of our platform. We use certified random number generators to ensure that every game is fair and transparent. This commitment to integrity is what sets us apart, making clarivoxstop a trusted space for all gamers.
            </Typography>
            <Typography variant="body1" paragraph>
              We are always eager to grow and improve. Whether you have feedback, questions, or are a game developer looking to share your work, we want to hear from you. Reach out to us at info@clarivoxstop.com and join us on this exciting journey. Together, we can make clarivoxstop the ultimate playground for free online gaming.
            </Typography>
            </Paper>
        </Box>

        <TopGames/>

        <Contacts />
      </Container>
    </>
  );
}
