import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {pattern} from "../assets";

function Copyright() {
  return (
    <Typography variant="body2" >
      {'Copyright © '}
      clarivoxstop {new Date().getFullYear()}{'.'}
    </Typography>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer" id="footer"
        sx={{
          px: 2,
          mt: 'auto',
        }} style={{
          background: `linear-gradient(120deg, rgba(29,26,41,.5) 32%, rgba(54,55,79,.5) 100%), url(${pattern})`, backgroundRepeat: "no-repeat", backgroundSize: "130%", backgroundPosition: "center",
        }}
        className="custom-shadow"
      >
         
        <Container maxWidth="sm">
        <Typography variant="h6">
          info@clarivoxstop.com
         </Typography>
         <Typography variant="h6">
         +14169233066         </Typography>
         <Typography variant="h6">
         452 Spadina Ave., Toronto, ON M5T 2G8, Canada         </Typography>
         <br/>
         <hr style={{border: "1px solid #6337d4", opacity: "0.2"}}/>
         <br/>
         <Typography variant="h6" fontWeight="900">
No money. Just fun. </Typography>

          <Typography variant="body1">
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy
            </Link>
          </Typography>
          <Copyright />
         
        </Container>
      </Box>
  );
}
