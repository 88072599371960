import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles'; // Import makeStyles for styling
import Grow from '@mui/material/Grow';

// Define styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 14, 
    borderRadius: '12px',
    minHeight: '200px', 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    opacity: "0.8",
    color: "#ffffff",
    background: 'linear-gradient(to left top, #401ada, #4021bc, #401ada)', 
  },
}));

export default function Benefits() {
  const classes = useStyles(); // Apply the defined styles

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="center" spacing={3}>
      <Grow in={true}  timeout={1000} appear={true}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
            Wide Selection of Games
            </Typography>
            <Typography variant="body1" paragraph>
            Discover a diverse range of games, including the latest releases and all-time classics. Our collection includes fantasy games, puzzle games, sports games, adventure games, and more.            </Typography>
          </Paper>
        </Grid>
      </Grow>

      <Grow in={true}  timeout={1200} appear={true}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
            Seamless Experience
            </Typography>
            <Typography variant="body1" paragraph>
            Our platform offers a seamless gaming experience without any interruptions. Play instantly without the need for downloads or logins. Enjoy an ad-free environment with no popups or distractions.            </Typography>
          </Paper>
        </Grid>
        </Grow>

        <Grow in={true}  timeout={1400} appear={true}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
            Multi-Device Compatibility            </Typography>
            <Typography variant="body1" paragraph>
            clarivoxstop games are playable on any device, whether you’re using a desktop, tablet, or mobile. Experience the same high-quality gameplay wherever you are.

</Typography>
          </Paper>
        </Grid>
        </Grow>
      </Grid>
    </Container>
  );
}
